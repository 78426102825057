import { gql } from '../__generated__'

export const NOTIFICATIONS_SUBSCRIPTION = gql(`
  subscription Notifications {
    notifications {
      ...NotificationFragment
    }
  }
`)

export const PROJECT_MILESTONES_SUBSCRIPTION = gql(`
  subscription ProjectMilestones($projectId: UUID!) {
    projectMilestones(projectId: $projectId) {
      type
      milestone {
        ...ProjectMilestoneFragment
      }
    }
  }
`)

export const DYNAMIC_REMINDERS_SUBSCRIPTION = gql(`
  subscription DynamicReminders {
    dynamicReminders {
      type
      dynamicPlanning {
        ...DynamicPlanningMilestoneFragment
      }
    }
  }
`)

export const MY_TASKS_SUBSCRIPTION = gql(`
  subscription MyTasksSubscription {
    myTasks {
      type
      task {
        ...MyTaskFragment
      }
    }
  }
`)

export const PROJECT_MILESTONE_TASKS_SUBSCRIPTION = gql(`
  subscription MilestoneTasks($milestoneId: UUID!) {
    milestoneTasks(milestoneId: $milestoneId) {
      type
      deletedStandbyTaskId
      task {
        ...TaskFragment
      }
    }
  }
`)

/* TASK SUB-TASKS */
export const PROJECT_MILESTONE_TASK_RELATED_SUBSCRIPTION = gql(`
  subscription TaskTree($rootTaskId: UUID!) {
    taskTree(rootTaskId: $rootTaskId) {
      type
      taskTree {
        ...TaskCardFragment
      }
    }
  }
`)

export const ACCOUNTS_SUBSCRIPTION = gql(`
  subscription Accounts {
    accounts {
      type
      account {
        ...AccountFullFragment
      }
    }
  }
`)

export const COMMENTS_SUBSCRIPTION = gql(`
  subscription Comments($entityId: UUID!, $type: CommentEntityType!) {
    comments(entityId: $entityId, type: $type) {
      type
      comment {
        ...CommentFragment
        thread {
          id 
          title
        }
      }
    }
  }
`)

export const PROJECTS_SUBSCRIPTION = gql(`
  subscription Projects {
    projects {
      type
      project {
        ...ProjectFragment
      }
    }
  }
`)

export const PROJECT_DETAILS_SUBSCRIPTION = gql(`
  subscription Project($id: UUID!) {
    project(projectId: $id) {
      type
      project {
        ...ProjectFragment
      }
    }
  }
`)

export const GOAL_DETAILS_SUBSCRIPTION = gql(`
  subscription Goal($goalId: UUID!) {
    goal(goalId: $goalId) {
      type
      goal {
        ...GoalDetailsFragment
      }
    }
  }
`)

export const BOARDS_SUBSCRIPTION = gql(`
  subscription Boards {
    boards {
      type
      board {
        ...BoardInfoFragment
      }
    }
  }
`)

export const BOARD_DETAILS_SUBSCRIPTION = gql(`
  subscription Board($boardId: UUID!) {
    board(boardId: $boardId) {
      type
    }
  }
`)

export const COLLECTIONS_SUBSCRIPTION = gql(`
  subscription FashionCollections {
    fashionCollections {
      type
      collection {
        ...FashionCollectionFragment
      }
    }
  }
`)

export const COLLECTION_DETAILS_SUBSCRIPTION = gql(`
  subscription FashionCollection($collectionId: UUID!) {
    fashionCollection(collectionId: $collectionId) {
      type
    }
  }
`)

export const ASSIGNED_GOALS_SUBSCRIPTION = gql(`
  subscription AssignedGoals {
    assignedGoals {
      type
      goal {
        ...MyGoalFragment
      }
    }
  }
`)

export const OWNED_GOALS_SUBSCRIPTION = gql(`
  subscription OwnedGoals {
    ownedGoals {
      type
      goal {
        ...MyGoalFragment
      }
    }
  }
`)

export const FOLLOWED_GOALS_SUBSCRIPTION = gql(`
  subscription FollowedGoals {
    followedGoals {
      type
      goal {
        ...MyGoalFragment
      }
    }
  }
`)

export const GOAL_TASKS_SUBSCRIPTION = gql(`
  subscription GoalTasks($goalId: UUID!) {
    goalTasks(goalId: $goalId) {
      type
      task {
        ...TaskFragment
      }
    }
  }
`)

export const COUNT_SUBSCRIPTION = gql(`
  subscription Counter($counterType: CounterType!, $entityId: UUID) {
    counter(counterType: $counterType, entityId: $entityId) {
      value
    }
  }
`)

export const USER_TASK_COUNTER_SUBSCRIPTION = gql(`
  subscription UserTaskCounter {
    userTaskCounter {
      assignedToMe
    }
  }
`)

export const MY_TASKS_COUNTERS_SUBSCRIPTION = gql(`
  subscription MyTasksCounters {
    userTaskCounter {
      assignedToMe
      assignedByMe
      following
      total
    }
  }
`)

export const TODOS_COUNTERS_SUBSCRIPTION = gql(`
  subscription ToDosCounters {
    userTodoCounter {
      assignedToMe
      assignedByMe
      following
      total
    }
  }
`)

export const PROJECT_SIZE_MATRIX_SUBSCRIPTION = gql(`
  subscription Subscription($projectId: UUID!) {
    projectSizeMatrix(projectId: $projectId) {
      type
      sizeMatrix {
        ...SizeMatrixFragment
      }
    }
  }
`)

export const SIZE_MATRICES_SUBSCRIPTION = gql(`
  subscription SizeMatrices {
    sizeMatrices {
      type
      sizeMatrix {
        ...SizeMatrixWithProjectFragment
      }
    }
  }
`)

export const RESOURCES_SUBSCRIPTION = gql(`
  subscription Resources($entityId: UUID!, $type: ContextType!) {
    resources(entityId: $entityId, type: $type) {
      type
      resource {
        id
        account {
          id
        }
      }
    }
  }
`)

export const RECURRING_DEFINITION_SUBSCRIPTION = gql(`
  subscription RecurringDefinition($recurringDefinitionId: UUID!) {
    recurringDefinition(recurringDefinitionId: $recurringDefinitionId) {
      type
      definition {
        ...RecurringTaskDefinitionFragment
      }
    }
  }
`)

export const RECURRING_DEFINITION_TASKS_SUBSCRIPTION = gql(`
  subscription RecurringDefinitionTasks($recurringDefinitionId: UUID!) {
    recurringDefinitionTasks(recurringDefinitionId: $recurringDefinitionId) {
      type
      task {
        ...TaskFragment
      }
    }
  }
`)

export const THREAD_COMMENTS_SUBSCRIPTION = gql(`
  subscription ThreadComments {
    threadComments {
      ...ThreadCommentActivityFragment
    }
  }
`)

export const THREAD_LIKES_SUBSCRIPTION = gql(`
  subscription ThreadLikes {
    threadLikes {
      ...ThreadLikeActivityFragment
    }
  }
`)

export const COMMENTS_READ_SUBSCRIPTION = gql(`
  subscription CommentsRead($entityId: UUID!) {
    commentsRead(entityId: $entityId) {
      account {
        ...AccountFragment
      }
      readAt {
        seconds
      }
    }
  }
`)
