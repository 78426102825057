import { gql } from '../__generated__'

export const SEARCH_THREADS = gql(`
  query SearchThreads($input: SearchThreadsInput!) {
    searchThreads(input: $input) {
      threads {
        ...ThreadInfoFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_RECENT_ACTIVITY_THREADS = gql(`
  query SearchRecentActivityThreads($pageRequest: PageRequestInput!, $unreadOnly: Boolean!) {
    recentThreads(pageRequest: $pageRequest, unreadOnly: $unreadOnly) {
      activities {
        ... on ThreadCommentActivity {
            thread {
                ...ThreadInfoFragment
            }
            recentParticipants {
                ...AccountFragment
            }
            lastParticipant {
                ...AccountFragment
            }
            time {
                seconds
            }
        }
        ... on ThreadLikeActivity {
            accounts {
              ...AccountFragment
            }
            comment {
              ...CommentFragment
              thread {
                id 
                title
              }
            }
          }
        ... on ThreadGoalActivity {
            isTerminal
            thread {
              id 
              title
              unreadCommentCount
            }
            convertedBy {
              ...AccountFragment
            }
            createdAt {
              seconds
            }
          }
        ... on ThreadTodoActivity {
            isTerminal
            thread {
              id 
              title
              unreadCommentCount
            }
            convertedBy {
              ...AccountFragment
            }
            createdAt {
              seconds
            }
          }
        }
      meta {
        ...PaginationMetadataFragment  
      }
    }
  }
`)

export const GET_THREAD = gql(`
  query GetThread($threadId: UUID!) {
    getThread(id: $threadId) {
      ...ThreadFragment
    }
  }
`)

export const GET_THREAD_COUNTERS = gql(`
    query GetThreadCounters {
      threadCounters {
      drafts
      favourites
      unread
      reminders
    }
  }
`)

export const GET_FAVOURITE_THREADS = gql(`
  query GetFavouriteThreads {
    favouriteThreads {
      ...ThreadFavouriteFragment
    }
  }
`)

export const GET_THREAD_DRAFTS = gql(`
  query AllThreadDrafts {
    allThreadDrafts {
      ...ThreadDraftInfoFragment
    }  
  }
`)

export const GET_DRAFT = gql(`
  query GetThreadDraft($draftId: UUID!) {
    getThreadDraft(id: $draftId) {
      ...ThreadDraftFragment
    }
  }
`)
