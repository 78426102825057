import { gql } from '../__generated__'

export const SEARCH_ORGANIZATIONS = gql(`
  query SearchOrganizations {
    searchOrganizations {
      ...OrganizationFullFragment
    }
  }
`)

export const SEARCH_PROJECT_TYPE = gql(`
  query searchProjectType($input: SearchNomenclatureInput) {
    searchProjectType(input: $input) {
      projectTypes {
        ...ProjectTypeFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const GET_CUSTOM_COLUMN_DEFINITIONS = gql(`
  query CustomFieldDefinitions {
    customFieldDefinitions {
    ...CustomFieldDefinitionFragment
    }
  }
`)

export const SEARCH_TIME_FACTOR = gql(`
  query searchTimeFactor($input: SearchNomenclatureInput) {
    searchTimeFactor(input: $input) {
      timeFactorsList {
        ...TimeFactorFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const GET_INVITATIONS = gql(`
  query getInvitations($input: GetInvitationsInput) {
    getInvitations(input: $input) {
      invitations {
        ...InvitationFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const GET_INVITEES_EMAILS = gql(`
  query getInviteesEmails($input: GetInvitationsInput) {
    getInvitations(input: $input) {
      invitations {
        id
        inviteeEmail
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const ALL_ACCOUNTS = gql(`
  query allAccounts {
    allAccounts {
      ...AccountFullFragment
    }
  }
`)

export const GET_USER_ACCOUNT = gql(`
  query GetCurrentUserAccount {
    getCurrentUserAccount {
      ...CurrentUserAccountFragment
      organization {
        ...OrganizationFragment
      }
    }
  }
`)

export const GET_CURRENT_ORGANIZATION = gql(`
  query GetCurrentOrganization {
    getCurrentUserAccount {
      id
      organization {
        ...OrganizationFullFragment
      }
    }
  }
`)

export const GET_USER_ACCOUNT_CALENDARS = gql(`
  query GetCurrentUserAccountCalendars($from: TimestampInput, $to: TimestampInput) {
    getCurrentUserAccount {
      id
      isGoogleCalendarEnabled
      isOutlookCalendarEnabled
      googleCalendarAuthorizationUrl
      outlookCalendarAuthorizationUrl
      googleAccountEmail
      outlookAccountEmail
      calendars {
        ...CalendarFragment
      }
      calendarEvents(from: $from,to: $to) {
        ...CalendarEventFragment
      }
    }
  }
`)

export const SEARCH_CLIENTS = gql(`
  query searchClients($input: SearchClientsInput) {
    searchClients(input: $input) {
      clients {
        ...ClientResponseFullFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_CLIENT_NAMES = gql(`
  query searchClientNames($input: SearchClientsInput) {
    searchClients(input: $input) {
      clients {
        id
        name
      }
    }
  }
`)

export const SEARCH_PRODUCERS = gql(`
  query searchProducers($input: SearchProducersInput) {
    searchProducers(input: $input) {
      producers {
        ...ProducerResponseFullFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_PRODUCER_NAMES = gql(`
  query searchProducerNames($input: SearchProducersInput) {
    searchProducers(input: $input) {
      producers {
        id
        name
      }
    }
  }
`)

export const SEARCH_JOB_TITLES = gql(`
  query searchJobTitle($input: SearchNomenclatureInput) {
    searchJobTitle(input: $input) {
      jobTitlesList {
        ...JobTitleFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }

`)

export const SEARCH_SIZE_STANDARTS = gql(`
  query searchSizeStandards($input: SearchNomenclatureInput) {
    searchSizeStandards(input: $input) {
      sizeStandards {
        ...SizeStandardFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_BASE_MILESTONES = gql(`
  query searchBaseMilestones($input: SearchNomenclatureInput) {
    searchBaseMilestones(input: $input) {
      baseMilestones {
        ...BaseMilestoneFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_TEAMS = gql(`
  query searchTeams($input: SearchTeamsInput) {
    searchTeams(input: $input) {
      teams {
        ...TeamFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_TEAMS_FULL = gql(`
  query searchTeamsFull($input: SearchTeamsInput) {
    searchTeams(input: $input) {
      teams {
        ...TeamFullFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_TEAM_NAMES = gql(`
  query searchTeamNames($input: SearchTeamsInput) {
    searchTeams(input: $input) {
      teams {
        id
        name
      }
    }
  }
`)

export const SEARCH_PROCEDURES = gql(`
  query searchProcedures($input: SearchProceduresInput) {
    searchProcedures(input: $input) {
      proceduresList {
        ...ProcedureFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_PROCEDURE_NAMES = gql(`
  query searchProcedureNames($input: SearchProceduresInput) {
    searchProcedures(input: $input) {
      proceduresList {
        id
        title
      }
    }
  }
`)

export const GET_PROCEDURE = gql(`
  query getProcedure($input: GetProcedureInput) {
    getProcedure(input: $input) {
      ...ProcedureFragment
    }
  }
`)

export const GET_PROCEDURE_WITH_PARTIAL_MILESTONES = gql(`
  query getProcedureWithPartialMilestones($input: GetProcedureInput) {
    getProcedure(input: $input) {
      ...ProcedureWithPartialMilestonesFragment
    }
  }
`)
export const SEARCH_CATEGORIES = gql(`
  query searchCategories($input: SearchNomenclatureInput) {
    searchCategories(input: $input) {
      categoriesList {
        ...CategoryFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_LABELS = gql(`
  query SearchLabels($input: SearchLabelsInput) {
    searchLabels(input: $input) {
      labels {
        ...LabelFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_ROLES = gql(`
  query SearchRoles {
    searchRoles {
      ...RoleFragment
    }
  }
`)

export const SEARCH_SUPPLIERS = gql(`
  query SearchSuppliers($input: SearchNomenclatureInput) {
    searchSuppliers(input: $input) {
      suppliers {
        ...SupplierFullFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_MANUFACTURERS = gql(`
  query SearchManufacturers($input: SearchNomenclatureInput) {
    searchManufacturers(input: $input) {
      manufacturers {
        ...ManufacturerFullFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_MANUFACTURERS_WITH_SUPPLIERS = gql(`
  query SearchManufacturersWithSuppliers($input: SearchNomenclatureInput) {
    searchManufacturers(input: $input) {
      manufacturers {
        ...ManufacturerFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_RESOURCE = gql(`
  query SearchResource($input: SearchResourceInput!) {
    searchResource(input: $input) {
      resources {
        ...ResourceWithEntityNamesFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_MATERIAL_TYPES = gql(`
  query SearchMaterialTypes($input: SearchNomenclatureInput!) {
    searchMaterialTypes(input: $input) {
      materialTypes {
        ...MaterialTypeFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const GET_ALL_ADDRESSES = gql(`
  query GetAllAddresses {
    allAddresses {
      ...AddressFragment
    }
  }
`)

export const GET_ALL_WAREHOUSES = gql(`
  query GetAllWarehouses {
    allWarehouses {
      ...WarehouseFragment
    }
  }
`)

export const GET_CATEGORIES_FOR_CONTEXT_TYPE = gql(`
  query GetCategoriesForContextType($input: GetCategoriesForContextTypeInput!) {
    getCategoriesForContextType(input: $input) {
      ...CategoryFragment
    }
  }
`)

export const GET_RESOURCE_ACCESS = gql(`
  query GetResourceAccess($input: GetResourceInput) {
    getResource(input: $input) {
      ...ResourceAccessFragment
    }
  }
`)

export const GET_RESOURCE_FULL = gql(`
  query GetResource($input: GetResourceInput) {
    getResource(input: $input) {
      ...ResourceFragment
      ...ResourceAccessFragment
    }
  }
`)

export const GET_RESOURCE_DOWNLOAD_URL = gql(`
  query GetResourceDownloadUrl($input: GetResourceInput) {
    getResource(input: $input) {
     id
     presignedDownloadUrl
    }
  }
  `)

export const GET_TEAM = gql(`
  query GetTeam($input: GetTeamInput!) {
    getTeam(input: $input) {
      ...TeamFragment
    }
  }
  `)

export const GET_TEAMS_BY_ACCOUNT = gql(`
  query GetTeamsByAccountId($input: GetTeamsByAccountIdInput!) {
    getTeamsByAccountId(input: $input) {
      ...TeamFragment
    }
  }
`)

export const GET_TEAMS_WITH_MEMBER_ACTIVITY = gql(`
  query GetTeamsWithMemberActivity($input: SearchTeamsInput, $periodInput: PeriodInput!) {
    searchTeams(input: $input) {
      teams {
        id
        isActive
        name
        teamLead {
          ...AccountFragment
        }
        members {
          ...TeamMemberFragment
          goals(period: $periodInput){
            activeGoals
            completedGoals
          }
          tasks(period: $periodInput){
            activeTasks
            completedTasks
          }
        }
      }
    }
  }
`)

export const CHECK_INVITATION = gql(`
  query CheckInvitation($input: CheckInvitationInput) {
    checkInvitation(input: $input) {
      isValid
      isAutoAccepted
    }
  }
`)

export const GET_RECENT_THREAD_CONTACTS = gql(`
  query RecentThreadContacts {
    recentThreadContacts {
      accounts {
        ...AccountFragment
      }
      teams {
        ...TeamFragment
      }
    }
  }
`)

export const GET_RECENT_THREAD_LABELS = gql(`
  query RecentThreadLabels {
    recentThreadLabels {
      labels {
        ...LabelFragment
      }
    }
  }
`)
