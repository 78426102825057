import { ADMIN_PANEL_PATHS } from '@components/Admin/Modules/paths'
import { Feature } from '@lib/graphql/__generated__/graphql'
import { ROUTER_PATHS } from './routerPaths'

export const PERMISSIONS = {
  ORGANIZATION_MANAGE: 'organization.manage',
  USER_MANAGE: 'user.manage',
  INVITATION_MANAGE: 'invitation.manage',
  TEAM_MANAGE: 'team.manage',
  JOB_TITLE_MANAGE: 'job_title.manage',
  PROJECT_TYPE_MANAGE: 'project_type.manage',
  TIME_FACTOR_MANAGE: 'time_factor.manage',
  BASE_MILESTONE_MANAGE: 'base_milestone.manage',
  PROCESS_TEMPLATE_MANAGE: 'process_template.manage',
  RESOURCE_CATEGORY_MANAGE: 'resource.category.manage',
  LABELS_MANAGE: 'labels.manage',
  CLIENT_MANAGE: 'client.manage',
  PROVIDER_MANAGE: 'provider.manage',
  RESOURCE_MANAGE: 'resource.manage',
  PROJECT_VIEW_PUBLIC: 'project.view.public',
  PROJECT_EDIT: 'project.edit',
  PROJECT_MANAGE: 'project.manage',
  PROJECT_CREATE: 'project.create',
  TASK_MANAGE: 'task.manage',
  BOARD_EDIT: 'board.edit',
  BOARD_MANAGE: 'board.manage',
  COLLECTION_MANAGE: 'collection.manage',
  GOAL_EDIT: 'goal.edit',
  GOAL_MANAGE: 'goal.manage',
  TODO_EDIT: 'todo.edit',
  MATERIAL_MANAGE: 'material.manage',
  NAVIGATION_PROJECTS: 'navigation.projects',
  NAVIGATION_DYNAMIC_REMINDERS: 'navigation.dynamic_reminders',
  NAVIGATION_REPORTS_TIME_TRACKING: 'navigation.reports.time_tracking',
  NAVIGATION_COLLECTIONS: 'navigation.collections',
  NAVIGATION_GOALS: 'navigation.goals',
  NAVIGATION_BOARDS: 'navigation.boards',
  NAVIGATION_ADMIN_USERS: 'navigation.admin.users',
  NAVIGATION_ADMIN_INVITATIONS: 'navigation.admin.invitations',
  NAVIGATION_ADMIN_JOB_TITLES: 'navigation.admin.job_titles',
  NAVIGATION_ADMIN_TEAMS: 'navigation.admin.teams',
  NAVIGATION_ADMIN_LABELS: 'navigation.admin.labels',
  NAVIGATION_ADMIN_CLIENTS: 'navigation.admin.clients',
  NAVIGATION_ADMIN_PROVIDERS: 'navigation.admin.providers',
  NAVIGATION_ADMIN_PROJECT_TYPES: 'navigation.admin.project_types',
  NAVIGATION_ADMIN_TIME_FACTORS: 'navigation.admin.time_factors',
  NAVIGATION_ADMIN_PROCESS_TEMPLATES: 'navigation.admin.process_templates',
  NAVIGATION_ADMIN_ACTIVITIES: 'navigation.admin.activities',
  NAVIGATION_ADMIN_RESOURCE_CATEGORIES: 'navigation.admin.resource_categories',
  NAVIGATION_ADMIN_APPLICATION_SETTINGS:
    'navigation.admin.application_settings',

  // Permissions below are not received from BE
  // They are used to identify similar permissions from BE which belong to this group
  FE_NAVIGATION_REPORTS_PREFIX: 'navigation.reports',
  FE_NAVIGATION_ADMIN_PREFIX: 'navigation.admin',
}

export const ADMIN_MODULE_PERMISSIONS = {
  [ADMIN_PANEL_PATHS.USERS]: PERMISSIONS.NAVIGATION_ADMIN_USERS,
  [ADMIN_PANEL_PATHS.INVITATIONS]: PERMISSIONS.NAVIGATION_ADMIN_INVITATIONS,
  [ADMIN_PANEL_PATHS.CLIENTS]: PERMISSIONS.NAVIGATION_ADMIN_CLIENTS,
  [ADMIN_PANEL_PATHS.PRODUCERS]: PERMISSIONS.NAVIGATION_ADMIN_PROVIDERS,
  [ADMIN_PANEL_PATHS.WORKING_DAYS]: PERMISSIONS.NAVIGATION_ADMIN_USERS,
  [ADMIN_PANEL_PATHS.JOB_TITLES]: PERMISSIONS.NAVIGATION_ADMIN_JOB_TITLES,
  [ADMIN_PANEL_PATHS.PROJECT_TYPES]: PERMISSIONS.NAVIGATION_ADMIN_PROJECT_TYPES,
  // TODO when permissions for project fields are added
  [ADMIN_PANEL_PATHS.PROJECT_FIELDS]:
    PERMISSIONS.NAVIGATION_ADMIN_PROJECT_TYPES,
  [ADMIN_PANEL_PATHS.TIME_FACTORS]: PERMISSIONS.NAVIGATION_ADMIN_TIME_FACTORS,
  [ADMIN_PANEL_PATHS.LABELS]: PERMISSIONS.NAVIGATION_ADMIN_LABELS,
  [ADMIN_PANEL_PATHS.MILESTONES]: PERMISSIONS.NAVIGATION_ADMIN_ACTIVITIES,
  [ADMIN_PANEL_PATHS.PROCEDURES]:
    PERMISSIONS.NAVIGATION_ADMIN_PROCESS_TEMPLATES,
  [ADMIN_PANEL_PATHS.SIZE_STANDARDS]: PERMISSIONS.PROJECT_EDIT,
  [ADMIN_PANEL_PATHS.TEAMS]: PERMISSIONS.NAVIGATION_ADMIN_TEAMS,
  [ADMIN_PANEL_PATHS.DOCUMENT_CATEGORIES]:
    PERMISSIONS.NAVIGATION_ADMIN_RESOURCE_CATEGORIES,
  [ADMIN_PANEL_PATHS.APPLICATION_SETTINGS]:
    PERMISSIONS.NAVIGATION_ADMIN_APPLICATION_SETTINGS,
}

export const ADMIN_MODULE_MANAGE_PERMISSIONS = {
  [ADMIN_PANEL_PATHS.USERS]: PERMISSIONS.USER_MANAGE,
  [ADMIN_PANEL_PATHS.INVITATIONS]: PERMISSIONS.INVITATION_MANAGE,
  [ADMIN_PANEL_PATHS.CLIENTS]: PERMISSIONS.CLIENT_MANAGE,
  [ADMIN_PANEL_PATHS.PRODUCERS]: PERMISSIONS.PROVIDER_MANAGE,
  [ADMIN_PANEL_PATHS.WORKING_DAYS]:
    PERMISSIONS.NAVIGATION_ADMIN_APPLICATION_SETTINGS,
  [ADMIN_PANEL_PATHS.JOB_TITLES]: PERMISSIONS.JOB_TITLE_MANAGE,
  [ADMIN_PANEL_PATHS.PROJECT_TYPES]: PERMISSIONS.PROJECT_TYPE_MANAGE,
  [ADMIN_PANEL_PATHS.TIME_FACTORS]: PERMISSIONS.TIME_FACTOR_MANAGE,
  [ADMIN_PANEL_PATHS.LABELS]: PERMISSIONS.LABELS_MANAGE,
  [ADMIN_PANEL_PATHS.MILESTONES]: PERMISSIONS.BASE_MILESTONE_MANAGE,
  [ADMIN_PANEL_PATHS.PROCEDURES]: PERMISSIONS.PROCESS_TEMPLATE_MANAGE,
  [ADMIN_PANEL_PATHS.SIZE_STANDARDS]: PERMISSIONS.PROJECT_EDIT,
  [ADMIN_PANEL_PATHS.TEAMS]: PERMISSIONS.TEAM_MANAGE,
  [ADMIN_PANEL_PATHS.DOCUMENT_CATEGORIES]: PERMISSIONS.RESOURCE_CATEGORY_MANAGE,
  [ADMIN_PANEL_PATHS.APPLICATION_SETTINGS]:
    PERMISSIONS.NAVIGATION_ADMIN_APPLICATION_SETTINGS,
}

export const MAIN_NAVIGATION_PERMISSIONS = {
  [ROUTER_PATHS.THREADS]: PERMISSIONS.NAVIGATION_GOALS,
  [ROUTER_PATHS.PROJECTS]: PERMISSIONS.NAVIGATION_PROJECTS,
  [ROUTER_PATHS.DYNAMIC_REMINDERS]: PERMISSIONS.NAVIGATION_DYNAMIC_REMINDERS,
  [ROUTER_PATHS.BOARDS]: PERMISSIONS.NAVIGATION_BOARDS,
  [ROUTER_PATHS.TO_DO]: PERMISSIONS.NAVIGATION_GOALS,
  [ROUTER_PATHS.MATERIALS_MANAGEMENT]: PERMISSIONS.MATERIAL_MANAGE,
  [ROUTER_PATHS.COLLECTIONS]: PERMISSIONS.NAVIGATION_PROJECTS,
  [ROUTER_PATHS.SIZE_MATRICES]: PERMISSIONS.NAVIGATION_PROJECTS,
  [ROUTER_PATHS.PRODUCTION_PLANNING]: PERMISSIONS.PROJECT_EDIT,
  [ROUTER_PATHS.REPORTS]: PERMISSIONS.FE_NAVIGATION_REPORTS_PREFIX,
}

export const MAIN_NAVIGATION_FEATURES = {
  [ROUTER_PATHS.SIZE_MATRICES]: Feature.Orders,
  [ROUTER_PATHS.COLLECTIONS]: Feature.FashionCollections,
  [ROUTER_PATHS.MATERIALS_MANAGEMENT]: Feature.Bom,
  [ROUTER_PATHS.PRODUCTION_PLANNING]: Feature.AdvancedProductionPlanning,
}

export const ADMIN_MODULE_FEATURES = {
  [ADMIN_PANEL_PATHS.SIZE_STANDARDS]: Feature.Orders,
}
